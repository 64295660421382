<template>
  <span class="detail-more">
    了解详情
    <img
      :src="isWhite ? require('@/assets/ShuJiuXiang/w-icon-detail@2x.png') : require('@/assets/ShuJiuXiang/b-icon-detail@2x.png')"
      alt=""
      class="b"
    />
    <img
      :src="require('@/assets/ShuJiuXiang/g-icon-detail@2x.png')"
      alt=""
      class="g"
    />
  </span>
</template>

<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.detail-more {
  display: flex;
  align-items: center;
  transition: 300ms;
  cursor: pointer;
  > img {
    width: 26px;
    height: 21px;
    margin-left: 8px;
  }
  .g {
    display: none;
  }
  @media screen and (min-width: $hover-point) {
    &:hover {
      color: $sub-color-2;
      .b {
        display: none;
      }
      .g {
        display: block;
      }
    }
  }
}
</style>