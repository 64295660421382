<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  render() {
    const arr = this.title.split('');
    return (
      <div class="title">
        {arr.map((d) => (
          <div>{d}</div>
        ))}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.title {
  color: #fff;
  line-height: math.div(100, 56);
  font-size: 56px;
  @media screen and (max-width: $res-point-3) {
    font-size: 56px * 0.8;
  }
  @media screen and (max-width: $res-point-7) {
    font-size: 56px * 0.8 * 0.8;
  }
  @media screen and (max-width: $res-point-8) {
    font-size: 56px * 0.8 * 0.8 * 0.8;
  }
  @media screen and (max-width: $res-point-9) {
    font-size: 20px;
    line-height: 1.5;
  }
}
</style>