<template>
  <div class="home-env-carousel">
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="item in data"
        :key="item.id"
      >
        <HomeEnvCard
          class="homeEnvCard"
          :data="item"
        />
      </swiper-slide>
      <div
        slot="pagination"
        class="pagination"
      ></div>
    </swiper>
  </div>
</template>

<script>
import HomeEnvCard from '../Card/HomeEnv.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  components: {
    HomeEnvCard,
    swiperSlide,
    swiper,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const $this = this;
    return {
      swiperOptions: {
        loop: true,
        speed: 1000, // 切换速度
        autoplay: {
          delay: 8000,
          pauseOnMouseEnter: true, // 鼠标置于swiper时暂停自动切换，鼠标离开时恢复自动切换
        },
        // grabCursor: true, // 拖动时指针会变成抓手形状
        pagination: {
          el: '.pagination',
          clickable: true,
          bulletClass: 'p-item',
          bulletActiveClass: 'p-active-item',
        },
        on: {
          click: function () {
            const len = $this.data.length;
            let index = this.clickedIndex - this.loopedSlides;
            index = index >= len ? index - len : index;
            if (isNaN(index)) return;
            $this.goDetail($this.data[index]);
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
  },
  methods: {
    goDetail({ id }) {
      this.$router.push(`/sjx/brand/env/detail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.pagination {
  margin-top: 70px;
  padding-left: math.percentage(math.div(354, 1920));
  padding-right: math.percentage(math.div(175, 1920));
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  &::before {
    content: '';
    left: 0;
    right: 0;
    height: 1px;
    position: absolute;
    background: $sub-color-4;
    top: 5px;
  }
  ::v-deep {
    .p-item {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: 1px solid $sub-color-4;
      cursor: pointer;
      position: relative;
      z-index: 9;
      background: $sub-color-5;
      + .p-item {
        margin-left: 80px;
      }
      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: $sub-color-1;
        width: 5px;
        height: 5px;
        top: 2px;
        left: 2px;
        @include hide;
        transition: 300ms;
      }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 0;
        background: $sub-color-4;
        left: 4px;
        bottom: 100%;
        margin-bottom: 8px;
        transition: 300ms;
      }
      &.p-active-item {
        &::before {
          @include show;
        }
        &::after {
          height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    padding-left: 5%;
  }
  @media screen and (max-width: $res-point-8) {
    margin-top: math.div(70, 600) * 100vw;
    ::v-deep {
      .p-item {
        + .p-item {
          margin-left: math.div(80 * 0.8, 600) * 100vw;
        }
        &.p-active-item {
          &::after {
            height: math.div(40 * 0.8, 600) * 100vw;
          }
        }
      }
    }
  }
}
.homeEnvCard {
  padding-right: math.percentage(math.div(320, 1920));
  @media screen and (max-width: $res-point-6) {
    padding-right: math.percentage(math.div(320 * 0.8, 1920));
  }
}
</style>