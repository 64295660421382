<template>
  <div class="home-env-card">
    <PlaceholderImage
      :width="1600"
      :height="820"
      :src="data.imgUrl"
      :alt="data.title"
    />
    <div class="txts">
      <div class="box">
        <p class="title">{{ data.title }}</p>
        <p class="desc">{{ data.desc }}</p>
        <div class="more">
          <DetailMore />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Placeholder } from '@/components/Image';
import { DetailMore } from '@/components/More';
export default {
  components: {
    PlaceholderImage: Placeholder,
    DetailMore,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-env-card {
  position: relative;
  padding-bottom: 40px;
  cursor: pointer;
  .txts {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
    padding-left: math.percentage(math.div(354, 1920));
  }
  .box {
    width: 465px;
    height: 285px;
    background: #fff;
    padding: 40px 95px 0 50px;
    border-radius: 2px;
    overflow: hidden;
  }
  .title {
    font-size: 28px;
    @include text-nowrap;
    line-height: math.div(37, 28);
    color: $sub-color-2;
    margin-bottom: 15px;
  }
  .desc {
    line-height: 28px;
    height: 28px * 3;
    overflow: hidden;
    margin-bottom: 50px;
    @include desc-wrap(3);
  }
  @media screen and (max-width: $res-point-6) {
    .txts {
      padding-left: 5%;
    }
    .box {
      background: rgba(255, 255, 255, 0.9);
    }
  }
  @media screen and (max-width: $res-point-7) {
    .box {
      width: 320px;
      padding: 28px 30px 0 30px;
      height: 220px;
      border-radius: 6px;
    }
    .title {
      font-size: 28px * 0.8;
      margin-bottom: 15px * 0.8;
    }
    .desc {
      height: 24px * 3;
      line-height: 24px;
      margin-bottom: 50px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    .box {
      width: 240px;
      height: 220px * 0.8 * 0.8;
      padding: 16px 15px 0 15px;
    }
    .title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    .desc {
      height: 24px * 2;
      @include desc-wrap(2);
      margin-bottom: 15px;
    }
  }
}
</style>