<template>
  <div
    class="carouselBox"
    v-if="!isOtherList"
  >
    <div class="carousel">
      <div class="cont">
        <el-carousel
          :autoplay="false"
          height="100%"
          arrow="never"
          ref="carousel"
        >
          <el-carousel-item
            v-for="item in data"
            :key="item.id"
          >
            <PlaceholderImage
              :src="item.imgUrl"
              :alt="item.title"
              :width="1010"
              :height="640"
            />
            <div class="txts">
              <div v-html="item.desc"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="controls">
      <div
        class="line"
        :style="{ top: `${moveLineTop}px` }"
      ></div>
      <div
        v-for="(item, index) in data"
        :key="item.id"
        class="item"
        :class="{ active: activeIndex === index }"
        @click="changeCarousel(index, $event)"
      >
        <div class="ms-left ms">
          <img
            :src="require('@/assets/ShuJiuXiang/h-ms-left@2x.png')"
            alt=""
            class="h"
          />
          <img
            :src="require('@/assets/ShuJiuXiang/b-ms-left@2x.png')"
            alt=""
            class="b"
          />
        </div>
        <div class="txts">
          <span
            class="sjx-iconfont"
            :class="item.icon"
          ></span>
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="ms-right ms">
          <img
            :src="require('@/assets/ShuJiuXiang/h-ms-right@2x.png')"
            alt=""
            class="h"
          />
          <img
            :src="require('@/assets/ShuJiuXiang/b-ms-right@2x.png')"
            alt=""
            class="b"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="carouselList"
  >
    <div
      class="item"
      v-for="item in data"
      :key="item.id"
    >
      <PlaceholderImage
        :src="item.imgUrl"
        :alt="item.title"
        :width="1010"
        :height="640"
      />
      <div class="txts">
        <p class="title">
          <span
            class="sjx-iconfont"
            :class="item.icon"
          ></span>{{ item.title }}
        </p>
        <div v-html="item.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Placeholder } from '@/components/Image';
import { getTargetByClass } from '@/utils/common';
import { mapState } from 'vuex';
const defaultTop = 32;
export default {
  components: {
    PlaceholderImage: Placeholder,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      moveLineTop: defaultTop,
    };
  },
  methods: {
    changeCarousel(index, ev) {
      const currentTarget = getTargetByClass(ev?.target, 'item');
      if (currentTarget) {
        this.moveLineTop = (currentTarget.offsetTop || 0) + defaultTop;
        this.activeIndex = index;
        this.$refs?.carousel.setActiveItem(index);
      }
    },
  },
  computed: {
    ...mapState(['visibleWH']),
    isOtherList() {
      const { width } = this.visibleWH;
      return width <= 481;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.carouselBox {
  display: flex;
  align-items: flex-end;
}
.carousel {
  width: math.percentage(math.div(1010, 1920 - 190 - 235));
  padding-bottom: 35px + 80px;
  .cont {
    padding-bottom: math.percentage(math.div(640, 1010));
    position: relative;
    ::v-deep {
      .el-carousel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -35px - 80px;
      }
    }
  }
  .txts {
    position: absolute;
    left: math.percentage(math.div(141, 1010));
    bottom: 80px;
    padding: 50px math.percentage(math.div(71, 1010)) 45px math.percentage(math.div(67, 1010));
    right: math.percentage(math.div(229, 1010));
    background: #fff;
    box-shadow: 0 32px 56px rgba(8, 15, 62, 0.16);
    line-height: 2;
  }
  ::v-deep {
    .el-carousel__indicators {
      display: none;
    }
  }
}
.controls {
  width: 230px;
  margin: 0 0 35px + 80px 110px;
  position: relative;
  .line {
    width: 130px;
    height: 3px;
    background: $sub-color-2;
    position: absolute;
    right: 100%;
    margin-right: 20px;
    top: 32px;
    z-index: 19;
    transition: 400ms linear;
  }
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    + .item {
      margin-top: 40px;
    }
  }
  .ms {
    > img {
      width: 45px;
      height: 45px;
      vertical-align: bottom;
    }
    .b {
      display: none;
    }
  }
  .txts {
    transition: 300ms;
    flex: 1;
    overflow: hidden;
    > span {
      display: block;
      text-align: center;
    }
  }
  .active {
    .txts {
      color: $sub-color-2;
    }
    .ms {
      .b {
        display: block;
      }
      .h {
        display: none;
      }
    }
  }
  .sjx-iconfont {
    font-size: 34px;
    line-height: 1;
  }
  .title {
    font-size: 16px;
    line-height: math.div(21, 16);
    margin-top: 12px;
    @include text-nowrap;
  }
}
@media screen and (max-width: $res-point-3) {
  .carousel {
    .txts {
      left: math.percentage(math.div(141 * 0.8, 1010));
      padding: 50px * 0.8 math.percentage(math.div(71 * 0.8, 1010)) 45px * 0.8
        math.percentage(math.div(71 * 0.8, 1010));
      right: math.percentage(math.div(141 * 0.8, 1010));
    }
  }
  .controls {
    .item + .item {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: $res-point-6) {
  .carouselBox {
    display: block;
  }
  .carousel {
    width: 100%;
    padding-bottom: 35px + 30px;
    .cont {
      ::v-deep {
        .el-carousel {
          bottom: -35px - 30px;
        }
      }
    }
    .txts {
      left: 5%;
      right: 5%;
      bottom: 30px;
      box-shadow: $card-shadow-1;
    }
  }
  .controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    .item {
      + .item {
        margin-top: 0;
      }
    }
    .line {
      display: none;
    }
    .sjx-iconfont {
      font-size: 26px;
    }
    .title {
      font-size: 14px;
      margin-top: 12px * 0.8;
    }
  }
}
@media screen and (max-width: $res-point-7) {
  .controls {
    .item {
      width: 32%;
    }
    .ms {
      > img {
        width: 30px;
        height: 30px;
      }
    }
    .sjx-iconfont {
      font-size: 26px * 0.8;
    }
    .title {
      font-size: 13px;
      margin-top: 12px * 0.8 * 0.8;
    }
  }
}
@media screen and (max-width: $res-point-8) {
  .carousel {
    .txts {
      border-radius: 6px;
      left: 15px;
      right: 15px;
      bottom: 20px;
      line-height: 1.5;
      padding: 20px 15px 25px;
      background: rgba(255, 255, 255, 0.9);
    }
  }
}
.carouselList {
  .item {
    background: #fff;
    box-shadow: $card-shadow-1;
    border-radius: 6px;
    overflow: hidden;
    + .item {
      margin-top: 15px;
    }
  }
  .txts {
    padding: 20px 15px 25px;
  }
  .title {
    display: flex;
    align-items: center;
    color: $sub-color-2;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .sjx-iconfont {
    margin-right: 5px;
    font-size: 18px;
    line-height: 1;
  }
}
</style>